export const about = {
  personalName: "KHEDID ABDELLAH",
  job: "DEVELOPER",
  descriptionFirstPart:
    "I’m a skilled web and mobile app developer specialized in the MERN stack. With expertise in React, MongoDB, Express, and Node.js, I create dynamic and responsive Web & Mobile applications.",
  descriptionSecondPart:
    " Let's build something exceptional together!",
  email: "khedidabdellah@gmail.com",
  age: "20",
  from: "Massira 1 , Marrakech",
  cvDownloadLink: "/downloadlink/",
  yearsOfExperience: "3",
  happyClients: "7",
  projectsDone: "8",
  getAwards: "2+",
};
