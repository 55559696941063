 
export const myEducations = [
  {
    firstDate:"2024",
    secondDate:"2023",
    diplomaORexperience:"FULL STACK DEVELOPER",
    location:"Marrakech",
    decription:"Second-year Digital Development student specializing in full-stack web at ISTA NTIC SYBA in Marrakech."
    
  },
  {
    firstDate:"2023",
    secondDate:"2022",
    diplomaORexperience:"FULL STACK DEVELOPER",
    location:"Marrakech",
    decription:"First year in Digital Development at ISTA NTIC SYBA in Marrakech."
    
  },
  {
    firstDate:"2022",
    secondDate:"2021",
    diplomaORexperience:"High School Diploma",
    location:"Marrakech",
    decription:"Obtained a High School Diploma in physical sciences with a French option, awarded with honors."
    
  }
];


 
export const myExperiences = [
    {
      firstDate:"2024",
      secondDate:"2023",
      diplomaORexperience:"DEVELOPER",
      location:"Marrakech",
      decription:"One-month internship at the Industrial Institute of Marrakech, where I completed three projects for the institute's administration."
      
    },
    
    {
      firstDate:"2024",
      secondDate:"2022",
      diplomaORexperience:"FREELANCER",
      location:"Worldwide",
      decription:"Part-time freelancer on various freelance platforms, and I also engage in e-commerce as a seller."
      
    },

  ];
  
  export const leftSkills = [
    { type: 'Html', level: 95,color: {bar: "#3498db",title: { background: '#2980b9' }} },
    //  { type: 'Css', level: 85,color: {bar: "#3498db",title: { background: '#2980b9' }} },
     { type: 'Tailwind CSS', level: 80,color: {bar: "#3498db",title: { background: '#2980b9' }} },
     { type: 'Javascript', level: 85 ,color: {bar: "#3498db",title: { background: '#2980b9' }} },
 
   ];
   export const rightSkills = [
      { type: 'React', level: 80,color: {bar: "#3498db",title: { background: '#2980b9' }} },
      // { type: 'Laravel', level: 70,color: {bar: "#3498db",title: { background: '#2980b9' }} },
      { type: 'WordPress', level: 75 ,color: {bar: "#3498db",title: { background: '#2980b9' }} },
      { type: 'Advertising', level: 70 ,color: {bar: "#3498db",title: { background: '#2980b9' }} },
    ];